import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StudentFollowUpListComponent } from './components/student-follow-up-list/student-follow-up-list.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { MsalGuard } from '@azure/msal-angular';
import { LoginFailedComponent } from './components/login-failed/login-failed.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthRedirectComponent } from './components/auth-redirect/auth-redirect.component';
import { RouterOptions } from '@tdoe/design-system';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/list' },
  { path: 'list', component: StudentFollowUpListComponent, canActivate: [MsalGuard] },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'login-failed', component: LoginFailedComponent },
  { path: 'auth-redirect', component: AuthRedirectComponent },
  { path: '**', pathMatch: 'full', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, RouterOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
