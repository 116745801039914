import { isNullOrUndefined } from "../utils";
import { ICTEConcentratorStudentFollowUp } from "./ICTEConcentratorStudentFollowUp";

export interface ICTEConcentratorStudentFollowUpViewModel {
    reportYear: number;
    concentrationAreaId: number;
    districtNo: number;
    districtName?: string;
    schoolNo: number;
    schoolName?: string;
    stateID: number;
    studentName?: string;
    courseCode?: string;
    yearCourseTaken?: number;
    gradeNumber?: string;
    careerCluster?: string;
    areaOfConcentrationPOS?: string;
    cohortYear: number;
    graduationType: string;
    includedInCohort: string;
    studentKey: number;
    highSchoolGraduated?: boolean;
    postSecAdvancedTraining?: string;
    nameOfEnrolledPostSecAdvancedTraining: string;
    nameOfEnrolledPostSecAdvancedTrainingName?: string;
    postProgramEnrolled?: number;
    majorOrCourseFocus?: string;
    tookRemedialCourse?: boolean;
    usedEPSOCredit: boolean;
    military: boolean;
    branchOfService: string;
    employed?: boolean;
    employer: string;
    jobTitle: string;
    annualIncome: number;
    relatedToHSCTEProgram: string;
    nationalCommunityServiceOrPeaceCorps?: boolean;
    exclude: boolean;
    reasonForExclusion: string;
    comments: string;
}

export function isComplete(concentrator: ICTEConcentratorStudentFollowUpViewModel): boolean {
    return !isNullOrUndefined(concentrator.postSecAdvancedTraining) &&
           !isNullOrUndefined(concentrator.postProgramEnrolled) &&
           !isNullOrUndefined(concentrator.military) &&
           !isNullOrUndefined(concentrator.employed) &&
           !isNullOrUndefined(concentrator.nationalCommunityServiceOrPeaceCorps);
}

export function toConcentrator(vm: ICTEConcentratorStudentFollowUpViewModel): ICTEConcentratorStudentFollowUp {
    return {
        schoolYear: vm.yearCourseTaken ?? vm.cohortYear,
        studentKey: vm.studentKey,
        concentrationAreaId: vm.concentrationAreaId,
        highSchoolGraduated: vm.highSchoolGraduated ?? false,
        posSecAdvancedTraining: vm.postSecAdvancedTraining,
        nameEnrolledPosSecAdvancedTraining: vm.nameOfEnrolledPostSecAdvancedTraining,
        posProgramEnrolled: vm.postProgramEnrolled ?? NaN,
        majorOrCourseFocus: vm.majorOrCourseFocus ?? '--',
        tookRemedialCourse: vm.tookRemedialCourse ?? false,
        usedEPSOCredit: vm.usedEPSOCredit,
        military: vm.military,
        militaryBranchOfService: vm.branchOfService,
        employed: vm.employed ?? false,
        employer: vm.employer,
        jobTitle: vm.jobTitle,
        
        annualIncome: vm.annualIncome,
        relatedToHSCTEProgram: vm.relatedToHSCTEProgram,
       nationalCommunityServiceOrPeaceCorps: vm.nationalCommunityServiceOrPeaceCorps ?? false,
       exclude: vm.exclude,
        reasonForExclusion: vm.reasonForExclusion,
        comments: vm.comments
    };
}
