import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { POSSecLookup } from 'src/app/interfaces/PosSecLookup';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class POSSecLookupService {

  private serviceUrl: string = `${environment.apiUrl}/api/POSSec`;

  constructor(private http: HttpClient) { }

  listLookups(): Promise<POSSecLookup[]> {
    return firstValueFrom(this.http.get<POSSecLookup[]>(`${this.serviceUrl}/list`));
  }

}
