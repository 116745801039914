import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { debounceTime, fromEvent, throttleTime } from 'rxjs';

@Directive({
  selector: '[vertical-spacinator]'
})
export class VerticalSpacinatorDirective implements AfterViewInit {
  @Input() minHeight?: number;
  @Input("fluidHeight") topOffset?: number;
  
  private domElement!: HTMLElement;

  constructor(private renderer: Renderer2, private element: ElementRef) {
    this.domElement = element.nativeElement;

    fromEvent(window, "resize")
      .pipe(throttleTime(500), debounceTime(500))
      .subscribe(() => this.setHeight());
  }

  ngAfterViewInit() {
    this.setHeight();
  }

  private calcTopOffset(): number {
    try {
      const rect = this.domElement.getBoundingClientRect();
      const scrollTop =
        window.scrollY || document.documentElement.scrollTop;

      return rect.top + scrollTop;
    } catch (e) {
      return 0;
    }
  }

  private setHeight() {
    const windowHeight = window?.innerHeight;
    const topOffset = this.topOffset || this.calcTopOffset();
    let height = windowHeight - topOffset;
  
    if (this.minHeight && height < this.minHeight) {
      height = this.minHeight;
    }
  
    this.renderer.setStyle(this.domElement, 'height', `${height}px`);
  }
}
