import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ClientConfiguration } from 'src/app/interfaces/clientConfiguration';
import { environment } from 'src/environments/environment';

import packageInfo from '../../../../package.json';
import { LoggingService } from '@tdoe/design-system';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  clientConfiguration$ = new BehaviorSubject<ClientConfiguration | undefined>(undefined);

  constructor(private http: HttpClient,
              private msal: MsalService,
              private logger: LoggingService) {

          
  }

  initialize(): void {
    this.http.get<ClientConfiguration>(`${environment.apiUrl}/api/configuration`).subscribe({
      next: (config) => {
        this.logger.debug('app.service.configuration', {
            title: 'NEXT',
            data: {
              config
            }
        });
        config.clientVersion = packageInfo.version;
        config.appName = packageInfo.displayName;
        this.clientConfiguration$.next(config);
      },
      error: (err: any) => {

      },
      complete: () => {
        this.logger.debug('app.service.configuration', {
            title: 'COMPLETE'
        });
      }
    });  
  }

  isStateAdmin(): boolean {
    const acct = this.msal.instance.getActiveAccount();
    if (acct?.idTokenClaims && acct?.idTokenClaims['groups'] && this.clientConfiguration$.value?.stateAdminGroupId) {
      const groups = acct.idTokenClaims['groups'] as string[];
      return groups.includes(this.clientConfiguration$.value?.stateAdminGroupId)
    }
    return false;
  }

  isStateUser(): boolean {
    const acct = this.msal.instance.getActiveAccount();
    if (acct?.idTokenClaims && acct?.idTokenClaims['groups'] && this.clientConfiguration$.value?.stateUserGroupId) {
      const groups = acct.idTokenClaims['groups'] as string[];
      return groups.includes(this.clientConfiguration$.value?.stateUserGroupId)
    }
    return false;
  }

}
