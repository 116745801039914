import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from '@tdoe/design-system';
import { catchError, of, retry } from 'rxjs';

export const lockoutInterceptor: HttpInterceptorFn = (req, next) => {
  const logger = inject(LoggingService);
  const router = inject(Router);
  logger.debug('lockout.interceptor', { data: { req } });
  return next(req)
    .pipe(
        retry(1),
        catchError((returnedError) => {
          router.navigate(['/unauthorized']);
          return of(returnedError);
        })
    );
};
