<div class="home-wrap">
  <div class="inner-wrap">
    <ng-container *ngIf="!authenticated;else welcome">
      <img src="/assets/login-required.jpg" alt="Login required">
      <div class="panel-large">
        <h6>Authorization required</h6>
        <p>Please login to use this app</p>
      </div>
    </ng-container>
    <ng-template #welcome>
      <img src="/assets/login-success.png" alt="Login required">
      <div class="panel-large">
        <h6>Welcome to Student Follow-Up!</h6>
        <button mat-raised-button color="primary" [routerLink]="['/list']">Get Started</button>
      </div>
    </ng-template>
  </div>
</div>