import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AppService } from 'src/app/services/app-service/app.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  apiVersion!: string;
  appVersion!: string;

  constructor(private appService: AppService) { }

  async ngOnInit(): Promise<void> {
    const config = await firstValueFrom(this.appService.clientConfiguration$);
    this.apiVersion = config?.apiVersion ?? '0.0.0';
    this.appVersion = config?.clientVersion ?? '0.0.0';
  }

}
