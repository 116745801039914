<h1 mat-dialog-title>About Enterprise Survey Portal</h1>
<div mat-dialog-content>
  <mat-list>
    <mat-list-item>
        <span mat-line [innerText]="appVersion"></span>
        <span mat-line>Client version</span>
    </mat-list-item>
    <mat-divider></mat-divider>
    <mat-list-item>
        <span mat-line [innerText]="apiVersion"></span>
        <span mat-line>API version</span>
    </mat-list-item>
  </mat-list>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close] cdkFocusInitial>Ok</button>
</div>