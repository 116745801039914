import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, firstValueFrom} from 'rxjs';
import { ICTEConcentratorStudentFollowUp } from '../interfaces/ICTEConcentratorStudentFollowUp';
import { ICTEConcentratorStudentFollowUpViewModel } from '../interfaces/ICTEConcentratorStudentFollowUpViewModel';
import { environment } from 'src/environments/environment';
import { DistrictSchoolMap } from '../interfaces/district-school-map';
import { PagedResult } from '../interfaces/paged-result';
import { Sort } from '@angular/material/sort';

@Injectable({
  providedIn: 'root'
})
export class CteConcentratorStudentFollowUpService {

  private serviceUrl: string = `${environment.apiUrl}/api/CTEConcentratorStudentFollowUps`;

  constructor(private http: HttpClient) { }

  getDistrictSchoolMap(reportYear: number): Promise<Record<string, DistrictSchoolMap>> {
    return firstValueFrom(this.http.get<Record<string, DistrictSchoolMap>>(`${this.serviceUrl}/uniquedistricts/${reportYear}`));
  }

  getStudentFollowUpList(reportYear: number, pageNumber: number, pageSize: number, districts?: number[], schools?: number[], sort?: Sort): Promise<PagedResult<ICTEConcentratorStudentFollowUpViewModel>> {
    let url = `${this.serviceUrl}/GetStudentFollowUpList/years/${reportYear}?pageNumber=${pageNumber}&pageSize=${pageSize}`;

    if (districts) {
      districts.forEach(_ => url += `&districts=${_}`);
    }

    if (schools) {
      schools.forEach(_ => url += `&schools=${_}`);
    }

    if (sort?.active) {
      url += `&sortColumn=${sort.active}`;
      if (sort?.direction === 'desc') {
        url += `&sortDirection=Descending`;
      } else {
        url += `&sortDirection=Ascending`;
      }
    }

    return firstValueFrom(this.http.get<PagedResult<ICTEConcentratorStudentFollowUpViewModel>>(url));
  }

  updateStudentFollowData(followUp: ICTEConcentratorStudentFollowUp): Promise<any> {
    return firstValueFrom(this.http.post<any>(`${this.serviceUrl}/UpdateStudentFollowUpData`, followUp));
  }
}
