<div>
    <mat-drawer-container>
        <mat-drawer-content>
            <!-- Main content -->
            <div class="name-label">
                <mat-form-field>
                    <mat-label>Graduating School Year</mat-label>
                    <mat-select (selectionChange)="handleDropdownChange($event)" [(value)]="reportYear">
                        <mat-option [value]="reportYear.value" *ngFor="let reportYear of reportYears">{{ reportYear.label }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <span style="flex-grow: 2;"></span>
                <mat-form-field style="flex-grow: 1;" *ngIf="tableColumns.includes('districtNo')">
                    <mat-label>Districts ({{ districtOptions.length }})</mat-label>
                    <mat-select (selectionChange)="districtChanged($event)" [(value)]="selectedDistricts" multiple>
                        <mat-option [value]="district.value" *ngFor="let district of districtOptions">{{ district.display ?? district.label }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field style="flex-grow: 1;">
                    <mat-label>Schools ({{ schoolOptions.length }})</mat-label>
                    <mat-select (selectionChange)="schoolChanged($event)" [(value)]="selectedSchools" multiple>
                        <mat-option [value]="school.value" *ngFor="let school of schoolOptions">{{ school.display ?? school.label }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div *ngIf="followUpData.length > 0;else noData">
                <table mat-table matSort class="mat-elevation-z8" (matSortChange)="onSortChange($event)" [dataSource]="followUpData">
            
                    <ng-container matColumnDef="districtNo">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>District #</th>
                        <td mat-cell *matCellDef="let followUp" [innerText]="followUp.districtNo"></td>
                    </ng-container>
                                
                    <ng-container matColumnDef="districtName">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>District</th>
                        <td mat-cell *matCellDef="let followUp" [innerText]="followUp.districtName"></td>
                    </ng-container>
                    
                    <ng-container matColumnDef="schoolNo">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>School #</th>
                        <td mat-cell *matCellDef="let followUp" [innerText]="followUp.schoolNo"></td>
                    </ng-container>
            
                    <ng-container matColumnDef="schoolName">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>School</th>
                        <td mat-cell *matCellDef="let followUp" [innerText]="followUp.schoolName"></td>
                    </ng-container>
                        
                    <ng-container matColumnDef="studentKey">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>State ID</th>
                        <td mat-cell *matCellDef="let followUp" [innerText]="followUp.studentKey"></td>
                    </ng-container>
            
                    <ng-container matColumnDef="studentName">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>Student name</th>
                        <td mat-cell *matCellDef="let followUp" [innerText]="followUp.studentName"></td>
                    </ng-container>
            
                    <ng-container matColumnDef="courseCode">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>Course code</th>
                        <td mat-cell *matCellDef="let followUp" [innerText]="followUp.courseCode"></td>
                    </ng-container>
            
                    <ng-container matColumnDef="yearCourseTaken">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>Year course taken</th>
                        <td mat-cell *matCellDef="let followUp" [innerText]="followUp.yearCourseTaken"></td>
                    </ng-container>
            
                    <ng-container matColumnDef="gradeNumber">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>Grade</th>
                        <td mat-cell *matCellDef="let followUp" [innerText]="followUp.gradeNumber ?? '--'"></td>
                    </ng-container>
            
                    <ng-container matColumnDef="careerCluster">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>Career cluster</th>
                        <td mat-cell *matCellDef="let followUp" [innerText]="followUp.careerCluster"></td>
                    </ng-container>
            
                    <ng-container matColumnDef="areaOfConcentrationPOS">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>Area of concentration (POS)</th>
                        <td mat-cell *matCellDef="let followUp" [innerText]="followUp.areaOfConcentrationPOS"></td>
                    </ng-container>

                    <ng-container matColumnDef="cohortYear">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>Cohort year</th>
                        <td mat-cell *matCellDef="let followUp" [innerText]="followUp.cohortYear"></td>
                    </ng-container>

                    <ng-container matColumnDef="graduationType">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>Graduation Type</th>
                        <td mat-cell *matCellDef="let followUp" [innerText]="followUp.graduationType"></td>
                    </ng-container>
            
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
                        <td mat-cell *matCellDef="let followUp">
                            <button color="primary" mat-raised-button (click)="edit(followUp, drawer)" *ngIf="canEdit">Edit</button>
                        </td>
                    </ng-container>
            
                    <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                    <tr mat-row [class.complete]="isComplete(followUpData)" *matRowDef="let followUpData; columns: tableColumns"></tr>
            
                </table>
        
                <mat-paginator *ngIf="pageSize > 0" class="mat-elevation-z8"
                    [pageSizeOptions]="[10, 25, 50, 100]"
                    [length]="totalCount"
                    [pageSize]="pageSize"
                    [pageIndex]="page - 1"
                    [showFirstLastButtons]="true"
                    (page)="paginationChanged($event)">
                </mat-paginator>
            </div>

            <ng-template #noData>
                <tdoe-alert [persistent]="true" type="warn">No follow-up data for selected criteria</tdoe-alert>
            </ng-template>
            
        </mat-drawer-content>
        <mat-drawer style="width: 350px" #drawer>
            <!-- Flyout panel -->
            <app-student-follow-up-edit-form (concentratorUpdated)="onConcentratorUpdated($event)" [concentrator]="selectedConcentrator"></app-student-follow-up-edit-form>
        </mat-drawer>
    </mat-drawer-container>

</div>