import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ICTEConcentratorStudentFollowUpViewModel, isComplete, toConcentrator } from 'src/app/interfaces/ICTEConcentratorStudentFollowUpViewModel';
import { IGridDropDownItem } from 'src/app/interfaces/IGridDropDownItem';
import { CteConcentratorStudentFollowUpService } from 'src/app/services/cte-concentrator-student-follow-up.service';
import { MatSelectChange } from '@angular/material/select';
import { LoadingService, LoggingService } from '@tdoe/design-system';
import { PageEvent } from '@angular/material/paginator';
import { MatDrawer } from '@angular/material/sidenav';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { AppService } from 'src/app/services/app-service/app.service';
import { Sort } from '@angular/material/sort';
import { DistrictSchoolMap } from 'src/app/interfaces/district-school-map';
import { ClientConfiguration } from 'src/app/interfaces/clientConfiguration';
import { orderBy } from 'lodash';

@Component({
  selector: 'app-student-follow-up-list',
  templateUrl: './student-follow-up-list.component.html',
  styleUrls: ['./student-follow-up-list.component.scss']
})
export class StudentFollowUpListComponent implements OnDestroy, OnInit {
  canEdit = false;
  configuration?: ClientConfiguration;
  disposables: Subscription[] = [];
  districtOptions: IGridDropDownItem[] = [];
  districtSchoolMaps?: Record<string, DistrictSchoolMap>;
  reportYears: IGridDropDownItem[] = [];
  drawer?: MatDrawer;
  reportYear!: number;
  loading:boolean = true;
  page = 1;
  pageSize = 20;
  schoolOptions: IGridDropDownItem[] = [];
  selectedConcentrator?: ICTEConcentratorStudentFollowUpViewModel;
  selectedDistricts?: string[];
  selectedSchools?: string[];
  showDistrictFilter = false;
  sortDir: Sort = {
    active: 'studentName',
    direction: 'asc'
  };
  tableColumns = [
    'schoolNo',
    'schoolName',
    'studentKey',
    'studentName',
    'courseCode',
    'yearCourseTaken',
    'gradeNumber',
    'careerCluster',
    'areaOfConcentrationPOS',
    'cohortYear',
    'graduationType',
    'actions'
  ];
  totalCount = 0;

  @Input() followUpData: ICTEConcentratorStudentFollowUpViewModel[] = [];

  private readonly _destroying$ = new Subject<void>();

  get districtFilter(): string[] {
    return this.selectedDistricts ?? this.scopedDistricts ?? [];
  }

  get isStateUser(): boolean {
    return this.appService.isStateAdmin() || this.appService.isStateUser();
  }

  get scopedDistricts(): string[] | null | undefined {
    return this.isStateUser ? null : this.configuration?.districtNumbers?.map(_ => _.toString());
  }

  constructor(private service: CteConcentratorStudentFollowUpService,
              private toasts: ToastrService,
              private logger: LoggingService,
              private appService: AppService,
              private loadingService: LoadingService) {

   }

   async districtChanged(event: MatSelectChange): Promise<void> {
    if (!this.districtSchoolMaps) return;
    
    delete this.selectedSchools;

    this.populateSchoolOptions();

    this.loadData(1);
   }

   populateSchoolOptions(): void {
    this.schoolOptions = [];
    if (this.districtFilter.length > 0) {
      this.scopeSchoolOptions();
    } else if (this.districtSchoolMaps) {
      Object.keys(this.districtSchoolMaps).forEach(district => {
        if (this.districtSchoolMaps) {
          const map = this.districtSchoolMaps[district];
          if (map?.schools) {
            this.schoolOptions.push(...map.schools.map(s => ({ display: `${s.value} - ${s.label}`, value: s.value })));
          }
        }
      });
    }
   }

   scopeSchoolOptions(): void
   {
      this.districtFilter.forEach(district => {
        if (this.districtSchoolMaps) {
          const map = this.districtSchoolMaps[district];
          if (map?.schools) {
            this.schoolOptions.push(...map.schools.map(s => ({ display: `${s.value} - ${s.label}`, value: s.value })));
          }
        }
      });
   }

   schoolChanged(event: MatSelectChange): void {
    this.loadData(1);
   }

   edit(followUp: ICTEConcentratorStudentFollowUpViewModel, drawer: MatDrawer): void {
    this.selectedConcentrator = followUp;
    this.drawer = drawer;
    drawer.open();
   }

   ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this.disposables?.forEach(_ => _.unsubscribe());
    this._destroying$.complete();
   }

  async ngOnInit(): Promise<void> {
    this.appService.clientConfiguration$.subscribe(_ => {
      if (_) {
        this.configuration = _;
        this.reportYears = orderBy(_?.reportYears ?? [],'value').reverse();
        this.handleDropdownChange({ value: new Date().getFullYear().toString() } as MatSelectChange);
        this.canEdit = _?.canWrite ?? false;
        if (this.appService.isStateUser() || this.appService.isStateAdmin()) {
          this.tableColumns = [
            'districtNo',
            'districtName',
            ...this.tableColumns
          ];
        }
      }
    });
  }

  onSortChange(sortState: Sort): void {
    this.sortDir = sortState;
    this.loadData(this.page);
  }
  
  async handleDropdownChange(event: MatSelectChange): Promise<void> {
    this.reportYear = event.value;
    this.loadData(1);
  }

  isComplete(followUp: ICTEConcentratorStudentFollowUpViewModel): boolean {
    return isComplete(followUp);
  }



  loadData(pageNumber: number): void {
    let districts: number[] | undefined = undefined;
    let schools: number[] | undefined = undefined;

    if (this.selectedDistricts) {
      districts = this.selectedDistricts.map(_ => parseInt(_));
    }

    if (this.selectedSchools) {
      schools = this.selectedSchools.map(_ => parseInt(_));
    }

    this.loadingService.show('Loading follow-up data...');

    Promise.all([
      this.service.getStudentFollowUpList(this.reportYear, pageNumber, this.pageSize, districts, schools, this.sortDir),
      this.service.getDistrictSchoolMap(this.reportYear)
    ]).then(promises => {
      const pagedResult = promises[0];
      this.totalCount = pagedResult.totalCount;
      this.followUpData = pagedResult.data ?? [];
      this.districtSchoolMaps = promises[1];
      this.remapFilters();
      this.loadingService.hide();
    });
  }

  remapFilters(): void {
    if (this.districtSchoolMaps) {
      this.districtOptions = [];

      Object.keys(this.districtSchoolMaps).forEach(_ => {
        const districtMap = this.districtSchoolMaps ? this.districtSchoolMaps[_] : undefined;
        if (districtMap) {
          this.districtOptions.push({
            display: `${_} - ${districtMap.districtName}`,
            value: _
          });
        }
      });
      this.populateSchoolOptions();
    }
  }

  selectPage(event: number): void {
    this.loadData(event);
  }

  paginationChanged(e: PageEvent): void {
    this.pageSize = e.pageSize;
    this.selectPage(e.pageIndex + 1);
  }

  async onConcentratorUpdated(vm: ICTEConcentratorStudentFollowUpViewModel): Promise<void> {
    this.drawer?.close();
    const concentrator = toConcentrator(vm);
    const index = this.followUpData.findIndex(_ => _.studentKey === vm.studentKey);
    try {
      await this.service.updateStudentFollowData(concentrator);
      this.followUpData.splice(index, 1, vm);
      this.toasts.success(`'${vm.studentName}' record updated!`, 'Successful update');
    } catch (err: any) {
      this.logger.error('student-follow-up-list.onConcentratorUpdated', {
          title: 'service.updateStudentFollowData',
          data: {
              vm,
              concentrator,
              index,
              followUpData: this.followUpData
          }
      });
      this.toasts.error(`Error while trying to update '${vm.studentName}'. See console for more information.`, 'Update failed');
    }
  }

}
